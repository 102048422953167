<template>
  <div class="relative">
    <div
      class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
    >
      <img
        src="/logo.svg"
        alt="Hire Truckers Logo"
        class="max-w-lg ring-1 w-36 md:w-72"
      />
    </div>
    <div
      class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
    >
      <img
        src="/logo.svg"
        alt="Hire Truckers Logo"
        class="w-32 max-w-lg ring-2 md:w-60"
      />
    </div>

    <img
      src="/logo.svg"
      alt="Hire Truckers Logo"
      class="max-w-lg w-28 main-logo md:w-52"
    />
  </div>
</template>

<style scoped>
@keyframes land {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.main-logo {
  animation: land 1s ease-in-out;
}

.ring-1,
.ring-2 {
  opacity: 0;
  box-shadow: none !important;
  will-change: auto;
}

@keyframes ring {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.ring-1 {
  animation: ring 1s;
}
.ring-2 {
  animation: ring 0.7s;
}
</style>
